import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private dialogIdSubject = new BehaviorSubject<string>('');
  private selectedCardSubject = new BehaviorSubject<any>(null);
  dialogId$ = this.dialogIdSubject.asObservable();
  selectedCard$ = this.selectedCardSubject.asObservable();
  setDialogId(dialogId: string) {
    this.dialogIdSubject.next(dialogId);
  }
  updateSelectedCard(card: any): void {
    this.selectedCardSubject.next(card);
  }
}

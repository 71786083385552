import { Component } from '@angular/core';
import { SnackbarService } from '@services';
import { SnackbarPayload } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'ui-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  toasts: SnackbarPayload[] | null = [];

  constructor(public toastService: SnackbarService) {
    toastService.toasts$.subscribe(toasts => (this.toasts = toasts));
  }

  remove(index: number) {
    this.toastService.hide(index);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'ui-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTitleComponent implements OnInit {
  dialogId: string;
  constructor(private sharedService: SharedService) {}
  @Input()
  title = '';

  @Input()
  highlightText = '';

  ngOnInit() {
    this.sharedService.dialogId$.subscribe((id: string) => {
      this.dialogId = id || '';
    });
  }
}

import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Template, User, Answer, Video } from 'altru-types';
import { VideoPlayerComponent } from '../../../shared/components/video-player/video-player.component';
import { FeedService, TrackService, WidgetMessageService } from '@services';
import {
  VideoStoppedOutput,
  VideoTrackProperties,
} from '../../../shared/components/video-player/video-player.model';
import {
  CardCTAClickedEvent,
  CardLikedEvent,
  CardSelectedEvent,
  CardSharedEvent,
  ConvertedUiCard,
  UiCard,
} from '@shared/components';
@Component({
  selector: 'altru-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss'],
})
export class DetailViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  card: UiCard;
  @Input()
  user: User;
  @Input()
  autoplay = true;
  @Input()
  template: Template;
  @Output()
  cardSelected = new EventEmitter<CardSelectedEvent>();
  @Output()
  cardLiked = new EventEmitter<CardLikedEvent>();
  @Output()
  cardShared = new EventEmitter<CardSharedEvent>();
  @Output()
  cardCtaClicked = new EventEmitter<CardCTAClickedEvent>();
  @HostBinding('class.ie-browser')
  checkIsIEOrEdge: boolean =
    isPlatformBrowser(this.platformId) &&
    /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  @ViewChild(VideoPlayerComponent, { static: false })
  videoPlayerComponent: VideoPlayerComponent;
  size: { width: number; height: number } = {
    width: 0,
    height: 0,
  };
  closeIcon: HTMLElement;
  exportedVideo = false;
  subscription: Subscription = new Subscription();
  constructor(
    private feedService: FeedService,
    private trackService: TrackService,
    private widgetMessageService: WidgetMessageService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit(): void {
    this.calcSize();
    this.initWidgetEventListeners();
    if (
      this.card.__sourceObject.build_instructions &&
      this.card.__sourceObject.build_instructions.layers &&
      this.card.__sourceObject.build_instructions.layers.length > 0
    ) {
      this.exportedVideo = true;
    }
  }
  initWidgetEventListeners(): void {
    this.subscription.add(
      this.widgetMessageService.events['Widget Clicked']?.subscribe(() => {
        this.videoPlayerComponent.play();
      })
    );

    this.subscription.add(
      this.widgetMessageService.events['Widget Closed']?.subscribe(() => {
        this.videoPlayerComponent.pause();
      })
    );
  }

  ngAfterViewInit(): void {
    this.closeIcon = this.document.querySelector('button.close-icon');
    this.closeIcon?.addEventListener('keydown', (e: KeyboardEvent) =>
      this.closeIconKeydown(e)
    );
    // Signal to the parent page to resize to ensure the modal is visible
    this.feedService.sendPageHeight(940);
    this.feedService.toggleVideoPopup('open');
  }
  ngOnDestroy(): void {
    this.feedService.sendPageHeight();
    this.feedService.toggleVideoPopup('close');
    this.subscription.unsubscribe();
  }
  onCardLiked(event: CardLikedEvent): void {
    this.cardLiked.emit(event);
  }
  onCardShared(event: CardSharedEvent): void {
    this.cardShared.emit(event);
  }
  onCardSelected(event: CardSelectedEvent): void {
    this.cardSelected.emit(event);
  }
  onCardCtaClicked(event: CardCTAClickedEvent): void {
    this.cardCtaClicked.emit(event);
  }
  calcSize(): void {
    const height = Math.min(this.card.height || 720, 720);
    const width = Math.min(this.card.width || 720, 720);
    if (height > width) {
      this.size = {
        height: height / 16,
        width: width / 16,
      };
    } else {
      this.size = {
        width: width / 16,
        height: height / 16,
      };
    }
  }
  closeIconKeydown(e: KeyboardEvent): void {
    if (e.code === 'Tab' && e.shiftKey) {
      e.preventDefault();
      (document.querySelector(
        'altru-detail-view .likes'
      ) as HTMLElement).focus();
    }
  }
  focusCloseButton({ event }): void {
    event.preventDefault();
    this.closeIcon?.focus();
  }
  videoPlayed(playedFirstTime: boolean, card: UiCard): void {
    if (!playedFirstTime) {
      this.trackService.track('SHOW_VIDEO', cardToTrackProps(card));
    }
  }
  videoStopped({ duration, lastTime }: VideoStoppedOutput, card: UiCard): void {
    const videoTrackProperties: VideoTrackProperties = {
      ...cardToTrackProps(card),
      ...{
        currentTime: lastTime,
        percentage: Math.min(Math.round((lastTime * 100) / duration), 100),
      },
    };
    this.trackService.track('VIDEO_STOPPED', videoTrackProperties);
  }
}
const cardToTrackProps = (card: UiCard): VideoTrackProperties => {
  const convertedCard = card as
    | ConvertedUiCard<Answer, 'answer'>
    | ConvertedUiCard<Video, 'video'>;
  const properties: VideoTrackProperties =
    convertedCard.__kind === 'answer'
      ? {
          answerId: convertedCard.__sourceObject.id,
          videoId: convertedCard.__sourceObject.video_id,
          questionId: convertedCard.__sourceObject.question_id,
        }
      : {
          videoId: convertedCard.__sourceObject.id,
        };
  return properties;
};

import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ComponentEntryDirective } from '@shared/directives/component-entry/component-entry.directive';

import { Store } from '@ngrx/store';
import * as UIActions from '@store/ui/ui.actions';
import { StoreShape } from '@store/action-reducers';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'altru-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnDestroy, AfterViewInit {
  @ViewChild(ComponentEntryDirective, { static: true })
  componentEntry: ComponentEntryDirective;
  dialogId: string;
  @ViewChild('closeButton', { static: false }) closeButton: ElementRef;

  @Output()
  closed: EventEmitter<Event> = new EventEmitter<Event>();

  isCloseButtonVisible = true;
  closeOnBackdropClick = true;
  opened = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private sharedService: SharedService,
    private store: Store<StoreShape>
  ) {
    this.store.dispatch(UIActions.modifyDialogState({ visibility: true }));
  }

  generateDialogId() {
    const dialogId =
      'dialog-' +
      Math.random()
        .toString(36)
        .substr(2, 9);
    this.sharedService.setDialogId(dialogId);
    return dialogId;
  }

  ngOnDestroy(): void {
    this.document.body.style.overflowY = 'auto';
    this.store.dispatch(UIActions.modifyDialogState({ visibility: false }));
  }
  ngAfterViewInit(): void {
    if (this.opened && this.isCloseButtonVisible && this.closeButton) {
      this.closeButton.nativeElement.focus();
    }
  }

  open(
    isCloseButtonVisible = true,
    closeOnBackdropClick = true,
    $event?: Event
  ): void {
    this.isCloseButtonVisible = isCloseButtonVisible;
    this.closeOnBackdropClick = closeOnBackdropClick;
    this.opened = true;
    this.dialogId = this.generateDialogId();
    this.changeDetector.detectChanges();
    this.document.body.style.overflowY = 'hidden';
  }

  close($event?: MouseEvent): void {
    if (
      !$event ||
      ($event.target as HTMLDivElement).classList.contains('modal-backdrop')
    ) {
      this.closed.emit($event);
      const url = new URL(window.location.href);
      const videoId = url.searchParams.get('video_id');
      this.sharedService.updateSelectedCard(videoId);
      this.opened = false;
    }
  }

  onBackdropClick($event: MouseEvent): void {
    if (
      ($event.target as HTMLDivElement).classList.contains('modal-backdrop') &&
      this.closeOnBackdropClick
    ) {
      this.close($event);
    }
  }

  onCloseButtonClick($event: MouseEvent): void {
    const videoId = new URLSearchParams(
      ($event as any)?.__nrNode?.attrs?.oldURL.split('?')[1]
    ).get('video_id');
    this.sharedService.updateSelectedCard(videoId);
    this.opened = false;
    setTimeout(() => {
      this.closed.emit($event);
    }, 150);
  }

  loadComponent(component: Type<any>): ComponentRef<any> {
    const componentEntryRef: ViewContainerRef = this.componentEntry
      .viewContainerRef;
    const componentFactory: ComponentFactory<Component> = this.componentFactoryResolver.resolveComponentFactory(
      component
    );

    componentEntryRef.clear();
    return componentEntryRef.createComponent(componentFactory);
  }

  clear(): void {
    this.componentEntry.viewContainerRef.clear();
  }
}
